// 代理商结算单计算方法
/******* 
 * @description: 计算应付服务费标准
 * @param {String} settleStandardType 结算标准 
 * @param {Object} contractData 合同数据
 * @param {String} payTimeAgreement 10预付款/20后付款
 * @return {Number} 应付服务费标准
 */
export function calculationStandard(settleStandardType,contractData,payTimeAgreement = '10',settleNum=0){
  const settleContent = JSON.parse(contractData.settleContent)//结算标准数据
  let agencyProportion = 0
  switch (settleStandardType) {
    case '10':
      agencyProportion = +settleContent.proportion
      break;
    case '20':
      agencyProportion = +settleContent.peopleNumfeeDeduction
      break;
    case '30':
      agencyProportion = +settleContent.peopleNumPay
      break;
    case '40':
      agencyProportion = +settleContent.classHourPay
      break;
    case '50':
      if(payTimeAgreement=='10'){
        // 预付款
        agencyProportion = +settleContent.prepayment
      }else if(payTimeAgreement == '20'){
        // 后付款
        agencyProportion = +settleContent.afterPaying
      }else{
        agencyProportion = 0
      }
      break;
    case '60':
      agencyProportion = +settleContent.personTimeList.find(e=>e.startNum<=settleNum&&e.endNum>=settleNum)?.price ?? ''
      break;
    case '70':
      agencyProportion = +settleContent.classHourfeeDeduction
      break;
    case '80':
      agencyProportion = +settleContent.subsidiesNumfeeDeduction
      break;
    default:
      break;
  }
  console.log(agencyProportion);
  isNaN(agencyProportion) && (agencyProportion = '')
  return agencyProportion
}


/******* 
 * @description: 代理商结算单相关计算方法
 * @param {String} settleStandardType 结算标准 
 * @param {Object} computData 需要计算的row
 * @return {Object} { agencyFee, agencyActualFee } 应付服务费/实付服务费
 */
export function proportionDiff (settleStandardType,computData) {
  const RESULT = {}
  switch (settleStandardType) {
    // 10 按比例付费 到账金额 * 标准/100
    case '10':
      RESULT.agencyFee = (
        +computData.actualReceivedFee * +computData.agencyProportion / 100
      ).toFixed(2);
      break;
    // 20 按人数 扣费 到账金额 - 结算人数 * 应付服务费标准
    case '20':
      RESULT.agencyFee = (
        +computData.actualReceivedFee - +computData.settleNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 30 按人数 付费 结算人数 * 应付服务费标准
    case '30':
      RESULT.agencyFee = (
        +computData.settleNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 40 按课时 付费 结算人数 * 应付服务费标准 * 课时数 
    case '40':
      RESULT.agencyFee = (
        +computData.settleNum *  +computData.lessonNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 50 按付款时间扣费 到账金额 - 结算人数 * (预付或后付标准)
    case '50':
      RESULT.agencyFee = (
        +computData.actualReceivedFee - +computData.settleNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 60 按人次阶梯扣费 到账金额 - 结算人数 * (阶梯标准)
    case '60':
      RESULT.agencyFee = (
        +computData.actualReceivedFee - +computData.settleNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 70 按课时扣费 到账金额 - 结算人数 * 课时标准 * 课时数
    case '70':
      RESULT.agencyFee = (
        +computData.actualReceivedFee - +computData.settleNum *  +computData.lessonNum * +computData.agencyProportion
      ).toFixed(2);
      break;
    // 80 按补贴人数扣费  到账金额 - （补贴人数 * 标准）-（未补贴人数 * 标准）
    case '80':
      RESULT.agencyFee = (
        +computData.actualReceivedFee - (+computData.settleNum * +computData.agencyProportion) - (+computData.notSubsidiesNum * +computData.notSubsidiesStandard)
      ).toFixed(2);
      break;

    default:
      break;
  }
  // 如果有到账比例
  if(+computData.receiptProportion>0){
    RESULT.agencyFee = (
      +RESULT.agencyFee * +computData.receiptProportion / 100
    ).toFixed(2);
  }else{
    RESULT.agencyFee = 0
  }
  // 如果有税点
  if (+computData.payTaxPoint > 0) {
    RESULT.agencyActualFee = (
      +RESULT.agencyFee * (100 - +computData.payTaxPoint) / 100
    ).toFixed(2);
  } else {
    RESULT.agencyActualFee = RESULT.agencyFee
  }

  isNaN(RESULT.agencyFee) && (RESULT.agencyFee = '')
  isNaN(RESULT.agencyActualFee) && (RESULT.agencyActualFee = '')

  console.log(RESULT);
  return RESULT
}