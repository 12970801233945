<template>
  <!-- 重构 - 结算管理 - 生成结算单（付款） -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goReturn()">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">生成结算单（代理商）</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
          <el-form-item label="结算信息" class="ts"></el-form-item>
          <el-form-item label="选择合同" prop="contractId" class="tsWidth">
            <el-select v-model="ruleForm.contractId" size="small" placeholder="请选择合同"><el-option v-for="item in fdNewContractLists" :key="item.contractId" :label="item.contractName" :value="item.contractId"></el-option>
            </el-select>
            <el-button type="text" @click="previewContract">预览合同</el-button>
            <!-- {{ruleForm.settleStandardType}}{{ruleForm.payTimeAgreement}} -->
          </el-form-item>
          <el-form-item style="width: 100%;margin-bottom: 0">
            <span v-if="ruleForm.settleStandardType == '50'" style="color: red">
              结算标准:
              <el-radio-group v-model="ruleForm.payTimeAgreement" @change="payTimeAgreementChange">
                <el-radio label="10">{{`预付费${JSON.parse(this.contractData.settleContent)['prepayment']}元/人`}}</el-radio>
                <el-radio label="20">{{`后付费${JSON.parse(this.contractData.settleContent)['afterPaying']}元/人`}}</el-radio>
              </el-radio-group>
            </span>
            <span v-else style="color: red">结算标准: {{settleStandardTypeStr}}</span>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <span style="color: red">代理商结算人数:{{ ruleForm.contractSettleNum || 0 }}人</span>
          </el-form-item>

          <el-form-item label="班级明细" class="btbzWitch">
            <el-table :data="ruleForm.fdNewProjectBOList" :key="tableKey" :highlight-current-row="true" size="small" tooltip-effect="dark" max-height="890" style="width: 100%" stripe show-summary :summary-method="getSummaries" class="tb-edit">
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200"></el-table-column>
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200"></el-table-column>
              <el-table-column v-if="lessonNumBoolean" label="课时数" align="left" show-overflow-tooltip prop="lessonNum"></el-table-column>
              <el-table-column label="结清状态" align="left" show-overflow-tooltip prop="settleState" min-width="150">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.settleState" placeholder="请选择" size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{
                    scope.row.settleState == 1 ? "已结清" : "未结清"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否预付款" align="left" show-overflow-tooltip prop="isAdvanceCharge" min-width="150">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.isAdvanceCharge" @change="isAdvancePaymentChange(scope.$index)" placeholder="请选择" size="small">
                    <el-option label="是" :value="true" />
                    <el-option label="否" :value="false" />
                  </el-select>
                  <span>{{
                    scope.row.isAdvanceCharge===true ? "是":scope.row.isAdvanceCharge === false?"否":'未设置'
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="班级单价" align="left" show-overflow-tooltip prop="projectPrice" width="100"></el-table-column>
              <el-table-column label="到账比例" align="left" show-overflow-tooltip prop="receiptProportion" width="100">
                <template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.receiptProportion" placeholder="请输入" :class="{'red':scope.row.receiptProportion == 0}" @keyup.native="scope.row.receiptProportion = zF.oninput2(
                          scope.row.receiptProportion,
                          2
                        )" @blur="algorithm(scope.$index)"></el-input>
                  <span :class="{'red':scope.row.receiptProportion == 0}">{{ scope.row.receiptProportion?scope.row.receiptProportion+'%': '0%' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="已结算人数" align="left" show-overflow-tooltip prop="settledNumber"></el-table-column>
              <el-table-column label="已结算代理费" align="left" show-overflow-tooltip prop="payerAmount" width="120"></el-table-column>
              <el-table-column v-if="ruleForm.settleStandardType != '80'" label="结算人数" align="left" show-overflow-tooltip prop="settleNum" width="100">
                <template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.settleNum" placeholder="请输入" @keyup.native="
                      scope.row.settleNum = zF.oninput2(scope.row.settleNum, 2)
                    " @blur="algorithm(scope.$index)"></el-input>
                  <span>{{ scope.row.settleNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="应收款(元)" align="left" show-overflow-tooltip prop="allMoney" width="100">
              </el-table-column>
              <el-table-column label="到账金额(元)" align="left" show-overflow-tooltip prop="actualReceivedFee" min-width="120"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.actualReceivedFee" placeholder="请输入" @keyup.native="
                      scope.row.actualReceivedFee = zF.oninput2(
                        scope.row.actualReceivedFee,
                        2
                      )
                    " @blur="algorithm(scope.$index)"></el-input>
                  <span>{{
                    scope.row.actualReceivedFee
                  }}</span>
                </template></el-table-column>
              <el-table-column label="显示" align="center" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.displayState" active-color="#13ce66" inactive-color="#ff4949"
                    :active-value="true" :inactive-value="false">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column v-if="ruleForm.settleStandardType != '80'" label="应付服务费标准(%、元)" align="left" show-overflow-tooltip prop="agencyProportion" min-width="150"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.agencyProportion" placeholder="请输入" @keyup.native="
                      scope.row.agencyProportion = zF.oninput2(
                        scope.row.agencyProportion,
                        2
                      )
                    " @blur="algorithm(scope.$index)"></el-input>
                  <span>{{ scope.row.agencyProportion }}</span>
                </template>
              </el-table-column>
              <template v-if="ruleForm.settleStandardType == '80'">
                <el-table-column label="领取补贴人数" align="left" show-overflow-tooltip prop="settleNum" width="100">
                  <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.settleNum" placeholder="请输入" @keyup.native="
                        scope.row.settleNum = zF.oninput2(scope.row.settleNum, 2)
                      " @blur="algorithm(scope.$index)"></el-input>
                    <span>{{ scope.row.settleNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="应付服务费标准(%、元)" align="left" show-overflow-tooltip prop="agencyProportion" min-width="150"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.agencyProportion" placeholder="请输入" @keyup.native="
                        scope.row.agencyProportion = zF.oninput2(
                          scope.row.agencyProportion,
                          2
                        )
                      " @blur="algorithm(scope.$index)"></el-input>
                    <span>{{ scope.row.agencyProportion }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="未领取补贴人数" align="left" show-overflow-tooltip prop="notSubsidiesNum" width="110">
                  <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.notSubsidiesNum" placeholder="请输入" @keyup.native="
                        scope.row.notSubsidiesNum = zF.oninput2(scope.row.notSubsidiesNum, 2)
                      " @blur="algorithm(scope.$index)"></el-input>
                    <span>{{ scope.row.notSubsidiesNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="应付服务费标准(%、元)" align="left" show-overflow-tooltip prop="notSubsidiesStandard" min-width="150"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.notSubsidiesStandard" placeholder="请输入" @keyup.native="
                      scope.row.notSubsidiesStandard = zF.oninput2(
                        scope.row.notSubsidiesStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index)"></el-input>
                    <span>{{ scope.row.notSubsidiesStandard }}</span>
                  </template></el-table-column>
              </template>
              <el-table-column label="应付服务费" align="left" show-overflow-tooltip prop="agencyFee" min-width="130"><template slot-scope="scope">
                  <!-- <el-input
                    size="small"
                    v-model="scope.row.agencyFee"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.agencyFee = zF.oninput2(
                        scope.row.agencyFee,2
                      )
                    "
                  ></el-input> -->
                  <span>{{ scope.row.agencyFee }}</span>
                </template></el-table-column>
              <el-table-column label="税点" align="left" show-overflow-tooltip prop="payTaxPoint" min-width="130">
                <template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.payTaxPoint" placeholder="请输入" @keyup.native="
                        scope.row.payTaxPoint = zF.oninput2(
                          scope.row.payTaxPoint,
                          2
                        )
                      " @blur="algorithm(scope.$index)"></el-input>
                  <span>{{ scope.row.payTaxPoint?scope.row.payTaxPoint+'%':'0%' }}</span>
                </template>
              </el-table-column>

              <el-table-column label="实付服务费" align="left" show-overflow-tooltip prop="agencyActualFee" min-width="130"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.agencyActualFee" placeholder="请输入" @keyup.native="
                      scope.row.agencyActualFee = zF.oninput2(
                        scope.row.agencyActualFee,
                        2
                      )
                    "></el-input>
                  <span>{{ scope.row.agencyActualFee }}</span>
                </template></el-table-column>
              <el-table-column label="培训机构" align="left" show-overflow-tooltip prop="compName" min-width="200"></el-table-column>
              <el-table-column label="收款结算单编号" align="left" show-overflow-tooltip prop="billNo" min-width="200"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="
                      scope.row.settleNum <= 1 || fatherData.addEdit == 'edit'
                    " @click="handleSplit(scope.$index, scope.row)">拆分
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="收费标准" prop="chargesSettlement" class="sfbzWidth">
            <el-input type="textarea" maxlength="1000" resize="none" placeholder="请输入收费标准" show-word-limit v-model="ruleForm.chargesSettlement"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" class="sfbzWidth">
            <el-input type="textarea" maxlength="500" resize="none" placeholder="请输入备注" show-word-limit v-model="ruleForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="收付款账号信息" class="ts"></el-form-item>
          <el-form-item label="付款单位" prop="payerName">
            <el-input v-model="ruleForm.payerName" placeholder="请输入付款单位" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="收款单位" prop="payeeName">
            <el-input v-model="ruleForm.payeeName" placeholder="请输入收款单位" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="payeeBank">
            <el-input v-model="ruleForm.payeeBank" placeholder="请输入开户银行" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="payeeAccount">
            <el-input v-model="ruleForm.payeeAccount" placeholder="请输入银行账号" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item class="operation">
            <el-button type="primary" class="bgc-bv" size="small" round @click="goReturn()">返回</el-button>
            <el-button type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 1)">预览结算单</el-button>
            <el-button type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 2)">生成结算单</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看班级明细 - loading -->
    <el-dialog title="查看课程明细" :visible.sync="dialogVisible1" width="50%" top="2%" center>
      <div id="pdf-cert1" style="height: 600px"></div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog title="预览合同" :visible.sync="dialogVisible3" width="950px" top="2%" center>
      <contractManagementAdd :seeTH="true" :seeContractId="ruleForm.contractId" v-if="dialogVisible3" />
    </el-dialog>
    <!-- 预览结算单 - loading -->
    <el-dialog title="预览结算单" :visible.sync="dialogVisible2" width="50%" top="2%" center>
      <div id="pdf-cert2" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import { calculationStandard, proportionDiff } from './agencyDiff';
export default {
  name: "singleAdministrationChildrenSecondEdit",
  components: {
    contractManagementAdd,
  },
  data () {
    return {
      // 父页面带过的参数
      fatherData: {
        id: "", // 待结算列表无id;结算单管理有id
        type: "", // 1-收款结算单;2-付款结算单
        addEdit: "", // add-待结算列表;edit-结算单管理
      },
      // 页面数据
      ruleForm: {
        contractSettleNum: 0, //代理商结算人数
        billId: "", // 结算单id
        contractId: "", // 合同
        compId: "", // 课程提供方id
        trainTypeId: "", // 培训类型id
        fdNewProjectBOList: [], // 结算明细 - 列表数据
        chargesSettlement: "", // 收费标准
        remark: "", // 备注
        payerName: "北京晟融信息技术有限公司", // 付款单位
        payeeName: "", // 收款单位
        payeeBank: "中国民生银行股份有限公司北京苏州街支行", // 开户银行
        payeeAccount: "154322354", // 银行账号
        settleStandardType: '', //结算标准
        payTimeAgreement: '10',//10预付款、20后付款
        settlementUserCount: 0, // 潜在字段 - 结算人数
        totalLessonNum: 0, // 潜在字段 - 总课时
        openLessonNum: 0, // 潜在字段 - 总公共课时
        payerAmount: 0, // 潜在字段 - 线上课程服务费（元）
      },
      contractData: {},//选中的合同数据
      // 数据校验
      rules: {
        // 选择合同
        contractId: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
        // 收费标准
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        // 付款单位
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        // 收款单位
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        // 开户银行
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        // 银行账号
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 预览合同 - loading
      dialogVisible3: false,
      // 列表里 - 结算状态
      options: [
        {
          value: 1,
          label: "已结清",
        },
        {
          value: 0,
          label: "未结清",
        },
      ],
      lessonNumBoolean: false,
      tableKey: Math.random(),

    };
  },
  created () {
    this.fatherData.id = JSON.parse(this.$route.query.editInfo).id;
    this.fatherData.type = JSON.parse(this.$route.query.editInfo).type;
    this.fatherData.addEdit = JSON.parse(this.$route.query.editInfo).addEdit;
    this.generateSettlementDocument();
  },
  computed: {
    // 结算标准文本
    settleStandardTypeStr () {
      let result = ''
      if (!this.contractData.settleContent) {
        return ''
      }
      const settleContent = JSON.parse(this.contractData.settleContent)//结算标准数据
      switch (this.ruleForm.settleStandardType) {
        case '10':
          result = `按比例付费,比例 ${settleContent.proportion} %`
          break;
        case '20':
          result = `按人数扣费,每人 ${settleContent.peopleNumfeeDeduction} 元`
          break;
        case '30':
          result = `按人数付费,每人 ${settleContent.peopleNumPay} 元`
          break;
        case '40':
          result = `按课时付费,${settleContent?.classHourPay ?? ''} 元/人/课时`
          break;
        case '50':
          result = `单选单独处理`
          break;
        case '60':
          let str = ''
          settleContent.personTimeList.forEach((e, index) => {
            str += `${e.startNum} ~ ${e.endNum}人 ${e.price} 元/人${settleContent.personTimeList.length != (index + 1) ? ', ' : ''}`
          })
          result = `按人次阶梯扣费 ${str} `
          break;
        case '70':
          result = `按课时扣费, ${settleContent.classHourfeeDeduction} 元/人/课时`
          break;
        case '80':
          result = `按补贴人数扣费,已领补贴 ${settleContent.subsidiesNumfeeDeduction} 元/人,未领补贴 ${settleContent.unSubsidiesNumfeeDeduction} 元/人`
          break;
        default:
          result = '无'
          break;
      }
      return result
    }
  },
  mounted () { },
  watch: {
    // 监听合同 - 变更：收费标准/付款单位
    "ruleForm.contractId": {
      handler: function (newVal) {
        // 合同数据
        this.contractData = this.fdNewContractLists.find(e => e.contractId == newVal);
        console.log(this.contractData);
        // 判断国家补贴标准-add:算初始值/edit:不算初始值
        if (this.fatherData.addEdit == "add") {
          this.dataAssignment()
        }
        const { settleStandardType } = this.contractData
        if (settleStandardType == '40' || settleStandardType == '70') {
          this.lessonNumBoolean = true
        } else {
          this.lessonNumBoolean = false
        }
        this.tableKey = Math.random()
      },
    },
  },
  methods: {
    // 面包屑 - 返回上个页面
    goReturn () {
      this.$router.push({
        path:
          this.fatherData.addEdit == "edit"
            ? "/web/financialSettlement/settlement/singleAdministration"
            : "/web/financialSettlement/settlement/toBeSettledList",
        query: {
          active: this.fatherData.type,
          refresh: true,
          multipleTable:
            this.fatherData.addEdit == "edit" ? "" : "multipleTable",
        },
      });
    },
    // 预览合同
    previewContract () {
      if (this.ruleForm.contractId) {
        this.dialogVisible3 = true;
      } else {
        this.$message.error("请选择合同");
      }
    },
    // 获取结算单数据
    generateSettlementDocument () {
        let _this = this
      if (this.fatherData.addEdit == "add") {
        this.$post("/biz/new/agentBill/generateStatement", {
          list: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              // 合同
              if (res.data.fdNewContractList.length) {
                this.fdNewContractLists = res.data.fdNewContractList; // 合同数据
                this.ruleForm.contractId =
                  res.data.fdNewContractList[0].contractId; // 默认赋值合同
                this.ruleForm.contractSettleNum =
                  res.data.fdNewContractList[0].contractSettleNum;
              }
              // 结算明细 - 列表数据
              res.data.selectedNodes.map((el) => {
                  el.settleState = 1;
                  // 列表显示字段
                  el.displayState = typeof el.displayState == 'boolean'? el.displayState : true;
                  // 如果是按补贴人数收费
                  if (res.data.fdNewContractList[0].settleStandardType == '80'){
                      el.settleNum = el.subsidiesNum;
                  }
              });

              this.ruleForm.fdNewProjectBOList = res.data.selectedNodes;
            }
          })
          .catch(() => {
            return;
          });
      }
      if (this.fatherData.addEdit == "edit") {
        this.$post("/biz/new/billAgent/operation/getInfo", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.fdNewProjectBOList = res.data.projectBOList; // 结算明细 - 列表数据
              if (res.data.fdNewContract.length) {
                this.fdNewContractLists = res.data.fdNewContract; // 合同数据
                this.ruleForm.contractSettleNum = res.data.contractSettleNum; // 代理商结算人数
                this.ruleForm.payTimeAgreement = res.data.payTimeAgreement || '10'; //预付后付 
                // 更改合同里的结算标准 - 只是更改当前页面的，实际合同里的数据不会改变
                for (let i = 0; i < res.data.fdNewContract.length; i++) {
                  if (res.data.fdNewContract[i].contractId == res.data.contractId) {
                    res.data.fdNewContract[i].chargesSettlement = res.data.chargesSettlement; // 结算标准
                    res.data.fdNewContract[i].compName = res.data.payeeName; // 收款单位
                    res.data.fdNewContract[i].partyBBank = res.data.payeeBank; // 开户行
                    res.data.fdNewContract[i].partyBAccount = res.data.payeeAccount; // 银行账号
                    this.ruleForm.settleStandardType = res.data.fdNewContract[i].settleStandardType; // 结算标准
                  }
                }
              }
              this.ruleForm.payerName = res.data.payerName; // 付款单位
              this.ruleForm.payerAmount = res.data.payerAmount; // 潜在字段 - 平台服务费（元）
              this.ruleForm.remark = res.data.remark; //备注
              this.ruleForm.billId = this.fatherData.id; // 结算单id
              this.ruleForm.chargesSettlement = res.data.chargesSettlement; // 收费标准
              this.ruleForm.payeeName = res.data.payeeName;  // 收款单位
              this.ruleForm.payeeBank = res.data.payeeBank; // 开户行
              this.ruleForm.payeeAccount = res.data.payeeAccount; // 银行账户
              this.ruleForm.contractId = res.data.contractId || ""; // 默认赋值合同
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 页面数据及班级明细赋值
    dataAssignment () {
      const settleContent = JSON.parse(this.contractData.settleContent)//结算标准数据
      // 合同数据赋值
      this.ruleForm.chargesSettlement = this.contractData.chargesSettlement; // 收费标准
      this.ruleForm.payeeName = this.contractData.compName;  // 收款单位
      this.ruleForm.payeeBank = this.contractData.partyBBank; // 开户行
      this.ruleForm.payeeAccount = this.contractData.partyBAccount; // 银行账户
      this.ruleForm.settleStandardType = this.contractData.settleStandardType; // 结算标准
      this.ruleForm.contractSettleNum = this.contractData.contractSettleNum;//结算人数
      // 班级明细赋值
      const { settleStandardType, payTimeAgreement } = this.ruleForm
      let addNum = this.contractData.contractSettleNum //列表累计结算人数
      this.ruleForm.fdNewProjectBOList.forEach((item, index) => {
        // 税点
        this.$set(item, "payTaxPoint", this.contractData.payTaxPoint);
        // 结算类型
        this.$set(item, "settleStyle", settleStandardType);
        if (settleStandardType == '60') {
          addNum += ~~item.settleNum
        }
        // 应付服务费标准(%、元)
        this.$set(item, "agencyProportion", calculationStandard(settleStandardType, this.contractData, payTimeAgreement, addNum));
        if (settleStandardType == '80') {
          // 未领取补贴人数
          // this.$set(item, "notSubsidiesNum", '');
          // 未领取补贴标准
          this.$set(item, "notSubsidiesStandard", settleContent.unSubsidiesNumfeeDeduction);
        }
        this.algorithm(index)
      })
    },
    // 结算标准50 应付标准change
    payTimeAgreementChange (val) {
      console.log(val);
      const { settleStandardType, payTimeAgreement } = this.ruleForm
      this.ruleForm.fdNewProjectBOList.forEach((item, index) => {
        // 应付服务费标准(%、元)
        this.$set(item, "agencyProportion", calculationStandard(settleStandardType, this.contractData, payTimeAgreement));
        if(val=='10') this.$set(item, "isAdvanceCharge", true);
        if(val=='20') this.$set(item, "isAdvanceCharge", false);
        this.algorithm(index)
      })
    },
    // 是否预付款change
    isAdvancePaymentChange (index) {
      const { settleStandardType, payTimeAgreement } = this.ruleForm
      // 应付服务费标准(%、元)
      if (settleStandardType == '50') {
        this.$set(this.ruleForm.fdNewProjectBOList[index], "agencyProportion", calculationStandard(settleStandardType, this.contractData, payTimeAgreement));
        this.algorithm(index)
      }
    },
    // 计算某一行的应付服务费和实付服务费
    algorithm (index, type = 'list') {
      if (type == "list") {
        let item = this.ruleForm.fdNewProjectBOList[index]
        const { settleStandardType, payTimeAgreement } = this.ruleForm
        const res = proportionDiff(settleStandardType, item, payTimeAgreement)
        this.$set(this.ruleForm.fdNewProjectBOList[index], 'agencyFee', res.agencyFee)
        this.$set(this.ruleForm.fdNewProjectBOList[index], 'agencyActualFee', res.agencyActualFee)
      }
    },

    // 生成/预览 - 结算单按钮;1-预览/2-生成
    generatePreview (formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hData = {
            contractId: this.ruleForm.contractId, // 合同
            chargesSettlement: this.ruleForm.chargesSettlement, // 收费标准
            contractSettleNum: this.ruleForm.contractSettleNum, // 代理商结算人数
            remark: this.ruleForm.remark, // 备注
            payerName: this.ruleForm.payerName, // 付款单位
            payeeName: this.ruleForm.payeeName, // 收款单位
            payeeBank: this.ruleForm.payeeBank, // 开户银行
            payeeAccount: this.ruleForm.payeeAccount, // 银行账号
          };
          if (this.ruleForm.settleStandardType == '50') {
            hData.payTimeAgreement = this.ruleForm.payTimeAgreement
          }
          if (this.ruleForm.billId) {
            hData.billId = this.ruleForm.billId;
          }
          let payerAmounts = 0;
          for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
            payerAmounts += Number(
              this.ruleForm.fdNewProjectBOList[i].agencyFee
            );
          }
          hData.projectBOList = this.ruleForm.fdNewProjectBOList; // 整理好的国家补贴标准列表
          hData.payerAmount = payerAmounts.toFixed(2); // 潜在字段 - 平台服务费（元）
          if (type == 1) {
            this.$post("/biz/new/agentBill/settleNum/confirm", hData).then(
              (res) => {
                if (res.data) {
                  this.$confirm(res.data, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                    .then(() => {
                      this.$post("/biz/new/agentBill/agentBillPdfPreview", hData)
                        .then((ret) => {
                          this.dialogVisible2 = true;
                          this.$nextTick(() => {
                            pdf.embed(ret.data, "#pdf-cert2");
                          });
                        })
                        .catch(() => {
                          return;
                        });
                    })
                    .catch(() => { });
                } else {
                  this.$post("/biz/new/agentBill/agentBillPdfPreview", hData)
                    .then((ret) => {
                      this.dialogVisible2 = true;
                      this.$nextTick(() => {
                        pdf.embed(ret.data, "#pdf-cert2");
                      });
                    })
                    .catch(() => {
                      return;
                    });
                }
              }
            );
          } else {
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/agentBill/settleNum/confirm", hData).then(
                (res) => {
                  if (res.data) {
                    this.$confirm(res.data, "提示", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    })
                      .then(() => {
                        this.$post("/biz/new/agentBill/saveStatement", hData)
                          .then((ret) => {
                            if (ret.status == 0) {
                              this.goReturn();
                              this.$message({
                                message: "生成成功",
                                type: "success",
                              });
                            }
                          })
                          .catch(() => {
                            return;
                          });
                      })
                      .catch(() => { });
                  } else {

                    this.$post("/biz/new/agentBill/saveStatement", hData)
                      .then((ret) => {
                        if (ret.status == 0) {
                          this.goReturn();
                          this.$message({
                            message: "生成成功",
                            type: "success",
                          });
                        }
                      })
                      .catch(() => {
                        return;
                      });
                  }
                }
              );
            } else {
              this.$post("/biz/new/agentBill/settleNum/confirm", hData).then((res) => {
                if (res.data) {
                  this.$confirm(res.data, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }).then(() => {
                    this.$post("/biz/new/billAgent/operation/saveStatement", hData).then((ret) => {
                      if (ret.status == 0) {
                        this.goReturn();
                        this.$message({ message: "编辑成功", type: "success" });
                      }
                    }).catch(() => {
                      return;
                    });
                  });
                } else {
                  this.$post("/biz/new/billAgent/operation/saveStatement", hData).then((ret) => {
                    if (ret.status == 0) {
                      this.goReturn();
                      this.$message({
                        message: "编辑成功",
                        type: "success",
                      });
                    }
                  }).catch(() => {
                    return;
                  });
                }
              }
              );
            }
          }
        }
      });
    },
    getSummaries (param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 0) {
          sums[index] = "合计";
        } else {
          // settledNumber计算总数
          if (column.property == 'settledNumber') {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            } else {
              sums[index] = "N/A";
            }
            // payerAmount计算总数
          } else if (column.property == 'payerAmount') {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0).toFixed(2);
            } else {
              sums[index] = "N/A";
            }
          } else {
            sums[index] = "";
          }
        }
      });
      return sums;
    },
    //拆分
    handleSplit (index, row) {
      this.ruleForm.fdNewProjectBOList.splice(index, 0, { ...row });
    },
  },
};
</script>
<style lang="less">
.demo-ruleForm {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 500px;
    .el-input {
      width: 260px;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
  .tb-edit .current-row .el-select + span {
    display: none;
  }
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
.el-textarea {
  .el-textarea__inner {
    min-height: 16rem !important;
    resize: none;
  }
}
span.red,
.red .el-input__inner {
  color: red;
}
</style>